export const accountTypeDropdown = [
    { key: 0, value: 'jobseeker' },
    { key: 1, value: 'employer' },
];

export const linkedInRequestDropDown = [
    {
        key: 0,
        label: 'Default',
        value: '',
    },
    {
        key: 1,
        label: 'Connected',
        value: 'CONNECTED',
    },
    {
        key: 2,
        label: 'Pending',
        value: 'REQUESTED',
    },
    {
        key: 3,
        label: 'Not in LinkedIn',
        value: 'NOT_IN_LINKEDIN',
    },
    {
        key: 4,
        label: 'Unable to request',
        value: 'UNABLE_TO_REQUEST',
    },
    {
        key: 5,
        label: 'Dud Profile',
        value: 'DUD_PROFILE',
    },
    {
        key: 6,
        label: 'Incomplete Profile',
        value: 'IN_COMPLETE_PROFILE',
    },
];

export const securityClearanceDropdown = [
    { value: 'NONE', label: 'None', key: 0 },
    { value: 'BASELINE', label: 'Baseline - Active', key: 1 },
    { value: 'BASELINE_LAPSED', label: 'Baseline - Lapsed / Reinstatable', key: 1 },
    { value: 'BASELINE_PENDING', label: 'Baseline - Pending Approval', key: 1 },
    { value: 'NV1', label: 'NV1 - Active', key: 2 },
    { value: 'NV1_LAPSED', label: 'NV1 - Lapsed / Reinstatable', key: 2 },
    { value: 'NV1_PENDING', label: 'NV1 - Pending Approval', key: 2 },
    { value: 'NV2', label: 'NV2 - Active', key: 3 },
    { value: 'NV2_LAPSED', label: 'NV2 - Lapsed / Reinstatable', key: 3 },
    { value: 'NV2_PENDING', label: 'NV2 - Pending Approval', key: 3 },
    { value: 'PV', label: 'PV - Active', key: 4 },
    { value: 'PV_LAPSED', label: 'PV - Lapsed / Reinstatable', key: 4 },
    { value: 'PV_PENDING', label: 'PV - Pending Approval', key: 4 },
    { value: 'ACTIVE_US', label: 'Active US security clearance', key: 0 },
    { value: 'ACTIVE_UK', label: 'Active UK security clearance', key: 0 },
    { value: 'OTHER_COUNTRY_SECURITY_CLEARANCE', label: 'Other country security clearance', key: 0 },
];
export const securityClearanceDropdown2 = [
    { value: 'NONE', label: 'None', key: 0 },
    { value: 'BASELINE', label: 'Baseline', key: 1 },
    { value: 'NV1', label: 'NV1', key: 2 },
    { value: 'NV2', label: 'NV2', key: 3 },
    { value: 'PV', label: 'PV', key: 4 },
];

export const securityClearanceRadio = [
    {
        value: 'BASELINE,BASELINE_LAPSED,BASELINE_PENDING,NV1,NV1_LAPSED,NV1_PENDING,NV2,NV2_LAPSED,NV2_PENDING,PV,PV_LAPSED,PV_PENDING',
        label: 'Aust. Security Clearance Required',
        key: 1,
    },
    // { value: 'NV1,NV1_LAPSED,NV1_PENDING,NV2,NV2_LAPSED,NV2_PENDING,PV,PV_LAPSED,PV_PENDING', label: 'NV1 (and above)', key: 2 },
    // { value: 'NV2,NV2_LAPSED,NV2_PENDING,PV,PV_LAPSED,PV_PENDING', label: 'NV2 (and above)', key: 3 },
    // { value: 'PV,PV_LAPSED,PV_PENDING', label: 'PV', key: 4 },
];

export const employmentModalDropdown = [
    { value: 'FULL_TIME', label: 'Full-time' },
    { value: 'PART_TIME', label: 'Part-time' },
    { value: 'CASUAL', label: 'Casual' },
    { value: 'CONTRACT', label: 'Contract' },
    { value: 'GRADUATE_PROGRAM', label: 'Graduate Program' },
    { value: 'ADF_RESERVE_DAYS', label: 'ADF Reserve Days' },
    { value: 'INTERNSHIP', label: 'Internship' },
    { value: 'UPSKILLING_MENTORING_STEM_PROGRAM', label: 'Upskilling / Mentoring / STEM Program' },
    { value: 'VOLUNTEERING', label: 'Volunteering' },
    { value: 'OTHER', label: 'Other' },
];

export const profileReadiness = [
    {
        key: 1,
        value: 'SEEKING',
        label: 'Actively seeking work',
        sub: 'Your identity will be',
        keyword: 'exposed',
        hover: 'All employers will be able to view your full  profile, including identity, contact details and CV.',
    },
    {
        key: 2,
        value: 'OPEN',
        label: 'Open to offers',
        sub: 'Your identity will be',
        keyword: 'hidden',
        hover: 'All employers will be able to view your skills and experience, but only those you agree to connect with will see your identity, contact details and CV.',
    },
    {
        key: 3,
        value: 'NOT_SEEKING',
        label: 'Not currently seeking work',
        sub: 'Your entire profile will be',
        keyword: 'dormant',
        hover: 'Your entire profile will remain hidden from all employers in all searches, but will remain available to keep updated.',
    },
];

export const searchAttributeModifier = [
    { key: 4, value: 'MANDATORY', label: 'Essential', modifier: '16' },
    { key: 3, value: 'HIGHLY_DESIRABLE', label: 'Highly desirable', modifier: '8' },
    { key: 2, value: 'DESIRABLE', label: 'Desirable', modifier: '4' },
    { key: 1, value: 'NICE_TO_HAVE', label: 'Nice to have', modifier: '2' },
];

export const geoRadio = [
    { key: 1, value: 'NOT_AN_OPTION', label: 'Not an option' },
    { key: 2, value: 'ACCEPTABLE', label: 'Acceptable' },
    { key: 3, value: 'DESIRABLE', label: 'Desirable' },
];

export const workingArrangementDropdown = [
    { key: 2, value: 'on_site_only', label: 'On Site' },
    { key: 4, value: 'remote_working', label: 'Remote Working' },
    { key: 8, value: 'hybrid', label: 'Hybrid (on site/ remote)' },
    { key: 32, value: 'fifo_dido', label: 'FIFO / DIDO' },
];

export const seekingDropdown = [
    'Full-time',
    'Part-time',
    'Casual',
    'Contract',
    'Graduate Program',
    'ADF Reserve Days',
    'Internship / Work Experience',
    'Upskilling / Mentoring / STEM Program',
    'Volunteering',
    'FIFO',
    'Remote Working',
    'Join/Rejoin the ADF',
    'Join/Rejoin the APS',
    'Other',
];

export const auCitizenshipStatusDropdown = [
    { key: 1, value: 'Not_yet_eligible_for_employment_in_Australia', label: 'Not yet eligible for employment in Australia' },
    { key: 2, value: 'Visa_that_permits_part-time_work', label: 'Visa that permits part-time work' },
    { key: 3, value: 'Visa_that_permits_full-time_work', label: 'Visa that permits full-time work' },
    { key: 4, value: 'Permanent_Resident', label: 'Permanent Resident' },
    { key: 5, value: 'Australian_Citizen', label: 'Australian Citizen' },
];

export const affirmativeActionFilter = [
    { key: 0, label: 'Military Veterans', value: 'veteranStatus' },
    { key: 1, label: 'Defence Partner', value: 'defencePartner' },
    { key: 2, label: 'Workforce Returner', value: 'workforceReturner' },
    { key: 3, label: 'Aboriginal or Torres St Islander', value: 'aboriginalOrTorresStrait' },
    { key: 4, label: 'Gender - Female', value: 'genderFemale' },
    // { key: 5, label: 'Gender - Non-binary', value: 'genderNonBinary' },
    { key: 5, label: 'Disability', value: 'disabled' },
];

export const countryDropdown = [
    'Afghanistan',
    'Aland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos(Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo, Democratic Republic of the Congo',
    'Cook Islands',
    'Costa Rica',
    "Cote D'Ivoir",
    'Croatia',
    'Cuba',
    'Curacao',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands(Malvinas)',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea - Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and Mcdonald Islands',
    'Holy See(Vatican City State)',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran, Islamic Republic of',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    "Korea, Democratic People's Republic o",
    'Korea, Republic of',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    "Lao People's Democratic Republi",
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libyan Arab Jamahiriya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Macedonia, the Former Yugoslav Republic of',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia, Federated States of',
    'Moldova, Republic of',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestinian Territory, Occupied',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'Saint Barthelemy',
    'Saint Helena',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Serbia and Montenegro',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan, Province of China',
    'Tajikistan',
    'Tanzania, United Republic of',
    'Thailand',
    'Timor - Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'United States Minor Outlying Islands',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela',
    'Viet Nam',
    'Virgin Islands, British',
    'Virgin Islands, U.s',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
];

export const nationailityDropdown = [
    'afghan',
    'albanian',
    'algerian',
    'american',
    'andorran',
    'angolan',
    'antiguans',
    'argentinean',
    'armenian',
    'australian',
    'austrian',
    'azerbaijani',
    'bahamian',
    'bahraini',
    'bangladeshi',
    'barbadian',
    'barbudans',
    'batswana',
    'belarusian',
    'belgian',
    'belizean',
    'beninese',
    'bhutanese',
    'bolivian',
    'bosnian',
    'brazilian',
    'british',
    'bruneian',
    'bulgarian',
    'burkinabe',
    'burmese',
    'burundian',
    'cambodian',
    'cameroonian',
    'canadian',
    'cape verdean',
    'central african',
    'chadian',
    'chilean',
    'chinese',
    'colombian',
    'comoran',
    'congolese',
    'costa rican',
    'croatian',
    'cuban',
    'cypriot',
    'czech',
    'danish',
    'djibouti',
    'dominican',
    'dutch',
    'east timorese',
    'ecuadorean',
    'egyptian',
    'emirian',
    'equatorial guinean',
    'eritrean',
    'estonian',
    'ethiopian',
    'fijian',
    'filipino',
    'finnish',
    'french',
    'gabonese',
    'gambian',
    'georgian',
    'german',
    'ghanaian',
    'greek',
    'grenadian',
    'guatemalan',
    'guinea-bissauan',
    'guinean',
    'guyanese',
    'haitian',
    'herzegovinian',
    'honduran',
    'hungarian',
    'icelander',
    'indian',
    'indonesian',
    'iranian',
    'iraqi',
    'irish',
    'israeli',
    'italian',
    'ivorian',
    'jamaican',
    'japanese',
    'jordanian',
    'kazakhstani',
    'kenyan',
    'kittian and nevisian',
    'kuwaiti',
    'kyrgyz',
    'laotian',
    'latvian',
    'lebanese',
    'liberian',
    'libyan',
    'liechtensteiner',
    'lithuanian',
    'luxembourger',
    'macedonian',
    'malagasy',
    'malawian',
    'malaysian',
    'maldivan',
    'malian',
    'maltese',
    'marshallese',
    'mauritanian',
    'mauritian',
    'mexican',
    'micronesian',
    'moldovan',
    'monacan',
    'mongolian',
    'moroccan',
    'mosotho',
    'motswana',
    'mozambican',
    'namibian',
    'nauruan',
    'nepalese',
    'new zealander',
    'ni-vanuatu',
    'nicaraguan',
    'nigerien',
    'north korean',
    'northern irish',
    'norwegian',
    'omani',
    'pakistani',
    'palauan',
    'panamanian',
    'papua new guinean',
    'paraguayan',
    'peruvian',
    'polish',
    'portuguese',
    'qatari',
    'romanian',
    'russian',
    'rwandan',
    'saint lucian',
    'salvadoran',
    'samoan',
    'san marinese',
    'sao tomean',
    'saudi',
    'scottish',
    'senegalese',
    'serbian',
    'seychellois',
    'sierra leonean',
    'singaporean',
    'slovakian',
    'slovenian',
    'solomon islander',
    'somali',
    'south african',
    'south korean',
    'spanish',
    'sri lankan',
    'sudanese',
    'surinamer',
    'swazi',
    'swedish',
    'swiss',
    'syrian',
    'taiwanese',
    'tajik',
    'tanzanian',
    'thai',
    'togolese',
    'tongan',
    'trinidadian or tobagonian',
    'tunisian',
    'turkish',
    'tuvaluan',
    'ugandan',
    'ukrainian',
    'uruguayan',
    'uzbekistani',
    'venezuelan',
    'vietnamese',
    'welsh',
    'yemenite',
    'zambian',
    'zimbabwean',
];
export const jobStatus = [
    {
        key: 2,
        value: '1',
        label: 'Shared with Jobowners',
    },
    {
        key: 4,
        value: '2',
        label: 'Shared within your company',
    },
    {
        key: 8,
        value: '3',
        label: 'Shared with Jobseekers',
    },
    {
        key: 16,
        value: '4',
        label: 'Publicly Advertised',
    },
    {
        key: 32,
        value: '5',
        label: 'Closed & Archived',
    },
];

export const assessmentStatus = [
    {
        key: 5,
        value: 32,
        label: 'Highly suitable for this role',
        shortLabel: '5-Highly',
    },
    {
        key: 4,
        value: 16,
        label: 'Suitable for this role',
        shortLabel: '4-Likely',
    },
    {
        key: 3,
        value: 8,
        label: 'Potentially suitable for this role',
        shortLabel: '3-Potentially',
    },
    {
        key: 2,
        value: 4,
        label: 'Unlikely to be suitable for this role',
        shortLabel: '2-Unlikely',
    },
    {
        key: 1,
        value: 2,
        label: 'Not suitable for this role',
        shortLabel: '1-Not',
    },
];
